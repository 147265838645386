import React, { useState,useRef } from 'react';
import { graphql } from "gatsby"
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import tw from 'twin.macro'
import Layout from "../components/layout"

// import Swiper JS
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Mousewheel,Parallax } from 'swiper';

// import Swiper styles
import 'swiper/swiper-bundle.css'

SwiperCore.use([Mousewheel,Parallax]);


export const pageQuery = graphql`
 query {
   allStrapiHomepage{
    edges {
      node {
        seo {
            metaTitle
            metaDescription
            shareImage {
              url
            }
        },
        banner {
            background {
                url

            }
            mobileBackground {
                url
            }
            button {
              name
              url
            },
            backgroundType,
            title,
            headerColor,
            textColor
          }
      }
    }
   }
   allStrapiGlobal {
        edges {
            node {
                weixin,
                weibo,
                bilibili,
                toktik,
                linkedin,
                salePhone,
                centralPhone,
                infoEmail,
                recordNumber {
                    name
                    url
                }
            }
        }
    }
 }
`

export default function IndexPage({location,data}) {
    const [headerColor,setHeaderColor] = useState("text-black")
    const breakpoints = useBreakpoint()
    const swiperRef = useRef()
    const node = data.allStrapiHomepage.edges[0].node
    const nodeFooter = data.allStrapiGlobal.edges[0].node
    const styles = {
        container:(e,l)=> [
            tw`absolute z-[2] left-0 flex justify-center w-full text-center`,
            l?tw`bottom-20`:tw`bottom-0`,
            e === 'title' && tw`top-0 pt-[7.291667%] text-[40px] leading-snug sm:pt-[30%] sm:text-[calc(16px + 1.2vw)]`,
            e === 'group' && tw`items-center justify-center pb-[4.6875%] sm:flex-col`,
            e === 'svg' && tw`bottom-0 mb-[38px] animate-bounce md:hidden hover:cursor-pointer`
        ],
        button:(e,i,l)=>[
            tw`flex justify-center items-center rounded-full`,
            tw`w-[240px] h-10 border-[2px] m-2.5`,

            // Use props to conditionally style your components
            !e && tw`border-white text-white hover:bg-white hover:text-black`,
            e && tw`border-black text-black hover:bg-black hover:text-white`,
            !e && !i && tw`border-white text-black bg-white`,
            e && !i && tw`border-black text-white bg-black`,
        ]
    }
    const swiperNextFun=()=>{
        swiperRef.current.swiper.slideNext();
    }

    return (
        <Layout footer={false} headerColor={headerColor}>
            <GatsbySeo
            title={node.seo.metaTitle}
            description={node.seo.metaDescription}
            
            openGraph={{
                url: location.href,
                title: node.seo.metaTitle,
                description: node.seo.metaDescription,
                images: [
                  { url: node.seo.shareImage.url },
                ],
                site_name: 'sotto',
              }}
            />
            <div className="h-screen w-screen">
            
            <Swiper 
                mousewheel={true}
                slidesPerView={1} 
                direction={'vertical'} 
                ref={swiperRef}
                parallax={true}
                speed={1200}
                onSlideChange={(swiper) => {
                    let bool = swiper.slides[swiper.activeIndex].getAttribute("data-bool")
                    setHeaderColor(bool=="true"?"text-black":"text-white")
                }}
                className="w-full h-full"
            >
                {
                    node.banner.map((n,i)=>{
                        return (
                        <SwiperSlide className="w-full h-full flex items-center justify-center" data-bool={n.headerColor} key={i}>
                            <figure className="w-full h-full !absolute left-0 top-0">
                            {
                                (()=>{
                                    switch(n.backgroundType){
                                        case 'image' :
                                            return <img className="w-full h-full object-cover" src={`https://admin.cnsotto.com${breakpoints.sm?n.mobileBackground.url:n.background.url}`} />
                                        case 'gif' :
                                            return <img className="w-full h-full object-cover" src={`https://admin.cnsotto.com${breakpoints.sm?n.mobileBackground.url:n.background.url}`} />
                                        case 'video' :
                                            return <video autoplay="autoplay" muted="true" className="w-full" src={`https://admin.cnsotto.com${n.background.url}`} />
                                    }
                                })()
                                
                            }
                            </figure>
                            <div className={n.textColor?"text-black":"text-white"}>
                                <h1 css={styles.container('title')} data-swiper-parallax="-100" data-swiper-parallax-opacity="0" data-swiper-parallax-opacity="0">{n.title}</h1>
                                <ul css={styles.container('group',i === node.banner.length-1)} className={i == node.banner.length-1?'bottom-20':''} data-swiper-parallax="-200" data-swiper-parallax-opacity="0" data-swiper-parallax-opacity="0">
                                    {
                                        n.button.map((e,k)=>{
                                            return (
                                                <li key={k}><a css={styles.button(n.textColor,1)} href={e.url}>{e.name}</a></li>
                                            )
                                        })
                                    }
                                </ul>
                                {i?null:<svg width="24" height="15" viewBox="0 0 24 15" fill="none" onClick={swiperNextFun} css={styles.container('svg')}> <path d="M2 2L12 12L22 2" stroke="white" strokeWidth="3"/> </svg>}
                                {
                                    i === node.banner.length-1?
                                    <div className="container flex justify-center mx-auto absolute left-1/2 bottom-[2.604167%] z-[2] transform -translate-x-1/2 -translate-y-1/2 text-sm sm:bottom-0">
                                        <div className="flex items-center justify-center text-black sm:flex-col">
                                            <p>Sotto © {new Date().getFullYear()}  <a href={nodeFooter.recordNumber.url}>{nodeFooter.recordNumber.name}</a>  </p>
                                            <ul className="flex items-center ml-[36px] sm:ml-0 sm:mt-3">
                                                <li className="px-[6px]"><a href="/privacy">隐私与法律</a></li>
                                                <li className="px-[6px]"><a href="/contact">联系我们</a></li>
                                                <li className="px-[6px]"><a href="/job">工作机会</a></li>
                                                <li className="px-[6px]"><a href="/sale-service">售后服务</a></li>
                                            </ul>
                                        </div>
                                        <a href="https://gemfisher.com" className="absolute right-4 bottom-0 text-gray-light hover:text-black sm:hidden" target="_blank">Designed by Gemfisher.com</a>
                                    </div>:null
                                }
                            </div>
                        </SwiperSlide>
                        ) 
                    })
                }
            </Swiper>
            </div>
        </Layout>
    )
}

